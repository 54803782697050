/**
 *  Dit is pagina die alle werk items oplijst
 * */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import WorkList from '../components/WorkList'
import Pagination from '../components/Pagination'

export default class workAll extends React.Component {
  render() {
    const { data, pageContext, path } = this.props
    const { edges: posts } = data.allWpWerk
    const { title } = data.site.siteMetadata

    return (
      <Layout path={path}>
        <Helmet>
          <title>{`Werk | ${title}`}</title>
          <meta name="description" content="Alle werk" />
          <meta name="og:title" content={`Werk | ${title}`} />
          <meta name="og:description" content="Alle werk" />
        </Helmet>
        <WorkList posts={posts} arrowColor="#FFF" title="Alle werken" />
        <Pagination pageContext={pageContext} pathPrefix="/" />
      </Layout>
    )
  }
}

workAll.propTypes = {
  data: PropTypes.shape({
    allWpPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const workQuery = graphql`
  query WerkQuery($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allWpWerk(limit: $limit, skip: $skip) {
      edges {
        node {
          ...WorkListFields
        }
      }
    }
  }
`
