import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import parse from 'html-react-parser'
import { GatsbyImage } from 'gatsby-plugin-image'
import ArrowUp from 'react-ionicons/lib/ArrowUp'

export default class WorkList extends React.Component {
  render() {
    const { posts, arrowColor } = this.props

    return (
      <section className="section">
        <div className="container">
          <h1 className="is-uppercase">WERK</h1>
          <div className="columns is-multiline">
            {posts.map(({ node: post }) => (
              <div className="column is-6 is-flex" key={post.id}>
                <div className="inner-wrapper transparent-back shadow">
                  <div className="work-desc-img">
                    <Link to={`/werk/${post.slug}/`}>
                      {post.featuredImage && (
                        <GatsbyImage
                          image={
                            post.featuredImage?.node?.localFile?.childImageSharp
                              ?.gatsbyImageData
                          }
                          alt={post.featuredImage.node.altText}
                        />
                      )}
                    </Link>
                  </div>
                  <div className="work-desc-wrap flex-col is-flex">
                    <Link to={`/werk/${post.slug}/`} className="more-arrow">
                      <ArrowUp color={arrowColor} height="30px" width="30px" />
                    </Link>
                    <h2 className="inner-title">
                      <Link to={`/werk/${post.slug}/`}>
                        {parse(post.title)}
                      </Link>
                    </h2>
                    <div
                      className="inner-content"
                      dangerouslySetInnerHTML={{
                        __html: post.extra.inleiding,
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

WorkList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment WorkListFields on WpWerk {
    id
    title
    content
    extra {
      inleiding
    }
    slug
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, width: 660)
          }
        }
      }
    }
  }
`
